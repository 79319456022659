/// <reference types="@emotion/react/types/css-prop" />
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from 'fragments/ScrollToTop';
import Suspense from 'fragments/Suspense';
import TopBar from 'components/Topbar';
import Preloader from 'components/Preloader';
import {
  isNotDev,
  APPLICATION_QUESTIONNAIRE_PATH,
  APPLICATION_QUESTIONNAIRE_SUCCESS_PATH,
  APP_BASENAME,
  LOGIN_PATH,
  HELP_PATH,
  FEATURED_PATH,
  PAYMENT_SUCCESS_PATH,
  REGISTRATION_PATH,
  USER_PROFILE_PATH,
  USER_APPLICATION_PATH,
  USER_APPLICATION_SUCCESS_PATH,
  APPLICATION_ADMISSION_FORM_PATH,
  INSTRUCTIONS_PATH,
  CERTIFICATE_PATH,
  CERTIFICATES_PATH,
  APPLICATION_TEST_PATH,
  APPLICATION_TEST_INITIALIZE_PATH,
} from 'constants/app';
import 'styles/App.scss.css';
import 'styles/App.css';

const Login = React.lazy(() => import('components/Login'));
const Register = React.lazy(() => import('components/Register'));
const Home = React.lazy(() => import('components/Home'));
const User = React.lazy(() => import('components/User'));
// const FoundationsContest = React.lazy(() => import('components/Contest/Foundations'));
const PaymentSuccess = React.lazy(() => import('components/Payment/Success'));
const UserCourseApplication = React.lazy(() => import('components/Application/Course'));
const UserCourseApplicationSuccess = React.lazy(
  () => import('components/Application/Course/SuccessCourse')
);
const ApplicationQuestionnaire = React.lazy(() => import('components/Application/Questionnaire'));
const ApplicationTest = React.lazy(() => import('components/Application/Test'));
const ApplicationTestInitialize = React.lazy(
  () => import('components/Application/Test/Initialize')
);
const ApplicationQuestionnaireSuccess = React.lazy(
  () => import('components/Application/Questionnaire/Success')
);
const AdmissionForm = React.lazy(() => import('components/Application/AdmissionForm'));
const HelpPage = React.lazy(() => import('components/Home/Help'));
const InstructionsPage = React.lazy(() => import('components/Home/Help/ApplicationInstructions'));
const LostPage = React.lazy(() => import('components/Lost'));
const CertificatePage = React.lazy(() => import('components/Certificate'));
const CertificatesPage = React.lazy(() => import('components/Certificate/List'));
const FeaturedPage = React.lazy(() => import('components/Featured'));

toast.configure({
  // autoClose: 8000,
});

export default function App() {
  useEffect(() => {
    const onChunkErrorReloadPage = (e: ErrorEvent) => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) window.location.reload();
    };

    window.addEventListener('error', onChunkErrorReloadPage);
    return window.removeEventListener('error', onChunkErrorReloadPage);
  }, []);

  return (
    <Router {...(isNotDev ? { basename: APP_BASENAME } : {})}>
      <Preloader>
        <div className="content">
          <TopBar />
          <Switch>
            <Route exact path="/">
              <Suspense>
                <Home />
              </Suspense>
            </Route>
            <Route path={`${LOGIN_PATH}/:method?`}>
              <Suspense>
                <Login />
              </Suspense>
            </Route>
            <Route path={REGISTRATION_PATH}>
              <Suspense>
                <Register />
              </Suspense>
            </Route>
            <Route path={USER_PROFILE_PATH}>
              <Suspense>
                <User />
              </Suspense>
            </Route>
            <Route path={HELP_PATH}>
              <Suspense>
                <HelpPage />
              </Suspense>
            </Route>
            <Route path={FEATURED_PATH}>
              <Suspense>
                <FeaturedPage />
              </Suspense>
            </Route>
            <Route path={INSTRUCTIONS_PATH}>
              <Suspense>
                <InstructionsPage />
              </Suspense>
            </Route>
            <Route path={PAYMENT_SUCCESS_PATH}>
              <Suspense>
                <PaymentSuccess />
              </Suspense>
            </Route>
            <Route path={APPLICATION_QUESTIONNAIRE_SUCCESS_PATH}>
              <Suspense>
                <ApplicationQuestionnaireSuccess />
              </Suspense>
            </Route>
            <Route path={APPLICATION_QUESTIONNAIRE_PATH}>
              <Suspense>
                <ApplicationQuestionnaire />
              </Suspense>
            </Route>
            <Route path={APPLICATION_TEST_INITIALIZE_PATH}>
              <Suspense>
                <ApplicationTestInitialize />
              </Suspense>
            </Route>
            <Route path={APPLICATION_TEST_PATH}>
              <Suspense>
                <ApplicationTest />
              </Suspense>
            </Route>
            <Route path={APPLICATION_ADMISSION_FORM_PATH}>
              <Suspense>
                <AdmissionForm />
              </Suspense>
            </Route>
            <Route path={USER_APPLICATION_SUCCESS_PATH}>
              <Suspense>
                <UserCourseApplicationSuccess />
              </Suspense>
            </Route>
            <Route path={`${USER_APPLICATION_PATH}/:type?`}>
              <Suspense>
                <UserCourseApplication />
              </Suspense>
            </Route>
            <Route path={`${CERTIFICATE_PATH}/:certificateId`}>
              <Suspense>
                <CertificatePage />
              </Suspense>
            </Route>
            <Route path={CERTIFICATES_PATH}>
              <Suspense>
                <CertificatesPage />
              </Suspense>
            </Route>
            <Route>
              <Suspense>
                <LostPage />
              </Suspense>
            </Route>
          </Switch>
          <ScrollToTop />
        </div>
      </Preloader>
    </Router>
  );
}
