import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactModal.setAppElement('body');

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: 'https://f04e166cea994f958ee290bf8f699f61@o387419.ingest.sentry.io/5394484' });
  TagManager.initialize({ gtmId: 'GTM-T5SW79T' });
}

if (document.readyState === 'loading')
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', startup);
// `DOMContentLoaded` has already fired
else startup();

function startup() {
  ReactDOM.render(<App />, document.getElementById('root'));
}
