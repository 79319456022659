/** @jsxImportSource @emotion/react */
import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { css } from '@emotion/react';
import useWindowScroll from '@react-hook/window-scroll';
import { motion } from 'framer-motion';

import { DARK_ICON } from 'constants/colors';

export default function ScrollToTop() {
  const windowHeight = window.innerHeight;
  const scrollY = useWindowScroll(60);
  const showSTT = scrollY * 2 > windowHeight;

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return showSTT ? (
    <motion.div
      whileHover={{ scale: 1.2 }}
      css={css`
        background-color: ${DARK_ICON};
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 2vh;
        right: 2vh;
        border-radius: 15px;
        height: 30px;
        width: 30px;
      `}
      onClick={scrollToTop}
    >
      <FaChevronUp color="white" />
    </motion.div>
  ) : null;
}
