/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import BounceLoader from 'react-spinners/BounceLoader';
import BarLoader from 'react-spinners/BarLoader';

interface IProps {
  bar?: boolean;
  color?: string;
  size?: string;
  show?: boolean;
  cssString?: string;
  spaced?: boolean;
}

export default (props: IProps) => {
  const { cssString, color, bar, spaced = false, size, show = false } = props;
  const [isLoading, setIsLoading] = useState(show);
  let timeoutTracker: any;

  useEffect(() => {
    // do not show loader for a second
    timeoutTracker = setTimeout(() => setIsLoading(true), 1000);

    return () => {
      if (timeoutTracker) clearTimeout(timeoutTracker);
    };
  }, []);

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        ${spaced ? 'margin-top: 10vh;' : ''}
        ${cssString};
      `}
    >
      {bar ? (
        <BarLoader loading={isLoading} color={color || '#123abc'} />
      ) : (
        <BounceLoader
          loading={isLoading}
          color={color || '#123abc'}
          size={size === 'tiny' ? 15 : size === 'small' ? 25 : 65}
        />
      )}
    </div>
  );
};
