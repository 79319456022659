export const PRIMARY_APP = '#324bd9';
export const PRIMARY_BACKGROUND = '#F7F6E9';
export const PRIMARY_BACKGROUND_THICK = '#EFE9C6';
export const PRIMARY_BACKGROUND_COMPLEMENT = '#E3E0D8';
export const PRIMARY_BACKGROUND_DARK = '#DFD38E';
export const PRIMRARY_BACKGROUND_PALE = '#ebeadd';
export const PALE_SEPARATOR = '#9c9c9c';
export const PALER_SEPARATOR = '#d4d4d4';
export const PALEST_SEPARATOR = '#f5f5f5';
export const DARK_TEXT = '#5c5c5c';
export const DARK_ICON = '#4a4a4a';
export const DEFAULT_TEXT = '#1e2029';
export const ERROR = '#fc0000';
export const ERROR_PALE = '#ff3838';
export const SUCCESS = '#009000';
export const SUCCESS_PALE = '#458745';
export const SUCCESS_PALEST = '#e6f7ec';
export const CLOSE_ICON = '#D45B63';
