/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Redirect, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import useStore from 'globalstate';
import { getCookieValue } from 'utils/cookies';
import { getAuthRefreshURL, REGISTRATION_PATH } from 'constants/app';

type AuthCookie = {
  aud: string;
  dashboard: string;
  tokens: string;
  exp: number;
  email?: string;
  full: string;
  github?: string;
  iat: number;
  iss: string;
  roles: string;
  sub: string;
  userId: number;
  username: string;
  name: string;
};

interface IProps {
  children: JSX.Element;
}

export default function Preloader(props: IProps) {
  const [redirect, setredirect] = useState<string>();
  const [resettingToken, setResettingToken] = useState<boolean>(false);
  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [State, Actions] = useStore();
  const fromURL = new URLSearchParams(useLocation().search).get('from');

  const doRedirect = (path: string) => {
    setredirect(path);
    return setTimeout(() => {
      setredirect('');
    }, 1);
  };

  const resetCookie = async () => {
    setResettingToken(true);
    localStorage.setItem('lastReset', Date.now().toString());
    window.location.replace(getAuthRefreshURL(window.location.href));
  };

  const maybeResetCookie = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return;

    // has temp cookie
    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    if (!authInfo.userId)
      return doRedirect(fromURL ? `${REGISTRATION_PATH}?from=${fromURL}` : REGISTRATION_PATH);

    const lastResetRaw = localStorage.getItem('lastReset');
    if (!lastResetRaw) return resetCookie();

    const lastReset = parseInt(lastResetRaw);
    const oneMinute = 1000 * 60;
    if (lastReset < Date.now() + oneMinute) return;

    resetCookie();
  };

  const checkAuthentication = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie)
      return Actions.setState({ userDetails: null, isAuthenticated: false, token: null });

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const cookieHasExpired = authInfo.exp > Date.now();
    if (cookieHasExpired) return resetCookie();

    const isAuthenticated = !!authInfo.username;
    const isTA = authInfo.tokens ? authInfo.tokens.split(',').includes('ta') : false;
    Actions.setState({ isAuthenticated, isTA, userDetails: authInfo, token: maybeAuthCookie });
  };

  // const getLocation = async () => {
  //   try {
  //     const resp = await fetch(LOCATION_SERVICE_URL);
  //     const respJSON = await resp.json();
  //     const {
  //       geoplugin_countryName: country,
  //       geoplugin_countryCode: countryCode,
  //       geoplugin_currencyCode: currencyCode,
  //     } = respJSON;
  //     Actions.setState({ country, countryCode, currencyCode });
  //   } catch {
  //     Actions.setState(DEFAULT_LOCATION_DATA);
  //   }
  // };

  useEffect(() => {
    maybeResetCookie();
    checkAuthentication();
    sethasPreloaded(true);
    // getLocation();
  }, [State.triggerPreload]);

  if (redirect) return <Redirect to={redirect} />;

  if (!hasPreloaded)
    return (
      <div
        css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img
          src="/logo.svg"
          css={css`
            width: 6vw;
            height: auto;
            @media (max-width: 768px) {
              width: 66vw;
            }
          `}
        />
      </div>
    );

  if (resettingToken)
    return (
      <div
        css={css`
          display: fixed;
          height: 100vh;
          width: 100vw;
        `}
      >
        <div
          css={css`
            height: 100vh;
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <img
            src="/logo.svg"
            css={css`
              width: 6vw;
              height: auto;
              @media (max-width: 768px) {
                width: 66vw;
              }
            `}
          />
        </div>
      </div>
    );

  return props.children;
}
