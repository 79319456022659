/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useLocation } from 'react-router';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { FaUser, FaSignInAlt, FaCaretDown } from 'react-icons/fa';

import {
  APP_BASENAME,
  APP_BASEPATH,
  LOGIN_PATH,
  PAYMENT_PATH,
  USER_PROFILE_PATH,
  USER_APPLICATION_PATH,
  USER_ACTIVE_APPLICATION_PATH,
  APPLICATION_QUESTIONNAIRE_PATH,
  getLogoutURL,
  REGISTRATION_PATH,
  HELP_PATH,
  INSTRUCTIONS_PATH,
  COURSE_URL,
  CERTIFICATE_PATH,
  CERTIFICATES_PATH,
} from 'constants/app';

import { getCookieValue } from 'utils/cookies';
import { cleanURL } from 'utils/url';
import { TOP_BAR_HEIGHT } from 'constants/measures';

const loginButtonStyle = {
  marginRight: '10px',
};

// interface AuthCookie {
//   aud: string,
//   dashboard: string,
//   exp: number,
//   full: string,
//   iat: number,
//   iss: string,
//   roles: string,
//   sub: string,
//   userId: number,
//   username: string,
// }

export default function TopBar() {
  const location = useLocation();
  const noTopBarPaths = [
    { path: REGISTRATION_PATH },
    { path: LOGIN_PATH },
    { path: PAYMENT_PATH },
    { path: HELP_PATH },
    { path: APPLICATION_QUESTIONNAIRE_PATH },
    { path: INSTRUCTIONS_PATH },
    { path: CERTIFICATE_PATH, exact: true },
  ];
  if (
    noTopBarPaths.find(({ path, exact }) => {
      if (exact) {
        const currentPath = cleanURL(location.pathname).split('/').shift();
        return currentPath === cleanURL(path);
      }
      return location.pathname === '/' || location.pathname.startsWith(path);
    })
  )
    return null;

  const [isMenuOpen, toggleMenu] = useState(false); // for mobile

  const maybeAuthCookie = getCookieValue('border-patrol-jwt');

  let authInfo: any = {};
  let username = '';
  let cookieHasExpired = true;
  if (maybeAuthCookie) {
    authInfo = jwtDecode(maybeAuthCookie);
    cookieHasExpired = authInfo.exp > Date.now();
    username = authInfo.username;
  }
  const loggedInIndicator = username && !cookieHasExpired;
  const currentURL = cleanURL(window.location.href);

  const loginURL = `${APP_BASEPATH}${LOGIN_PATH}?from=${currentURL}`;

  // Link items
  const renderNavLink = ({ link, title }: { link: string; title: string }) => (
    <Link
      key={`navlink-${title}`}
      className="navbar-item"
      onClick={() => toggleMenu(false)}
      to={link}
    >
      {title}
    </Link>
  );

  return (
    <nav
      className="navbar is-transparent has-shadow"
      role="navigation"
      aria-label="main navigation"
      css={css`
        height: ${TOP_BAR_HEIGHT};
        * {
          font-size: 1.1rem;
        }
      `}
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="https://univ.ai">
          <img
            src={`${APP_BASENAME}/logo.svg`}
            css={css`
              width: 10vw;
            `}
          />
        </a>

        <a
          role="button"
          className={`navbar-burger burger${isMenuOpen ? ' is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="topBarNav"
          onClick={() => toggleMenu(!isMenuOpen)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div id="topBarNav" className={`navbar-menu${isMenuOpen ? ' is-active' : ''}`}>
        <div className="navbar-start">
          <Link className="navbar-item" to={HELP_PATH}>
            Contact Us
          </Link>
        </div>

        <div className="navbar-end">
          {loggedInIndicator ? (
            <React.Fragment>
              <div className="navbar-link is-arrowless">
                <a className="navbar-item" href={COURSE_URL}>
                  My Programs
                </a>
              </div>
              <div className="navbar-link is-arrowless">
                <Link className="navbar-item" to={USER_ACTIVE_APPLICATION_PATH}>
                  My Applications
                </Link>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  <a
                    className="button is-primary is-outlined"
                    css={css`
                      min-width: 12.5rem;
                    `}
                  >
                    <FaUser style={loginButtonStyle} />
                    {username}
                    <FaCaretDown
                      css={css`
                        margin-left: 5px;
                      `}
                    />
                  </a>
                </div>
                <div className="navbar-dropdown">
                  {[
                    { link: CERTIFICATES_PATH, title: 'My Certificates' },
                    { link: USER_APPLICATION_PATH, title: 'Apply for Programs' },
                    { link: USER_PROFILE_PATH, title: 'My Profile' },
                  ].map(renderNavLink)}
                  <a className="navbar-item" href={getLogoutURL(currentURL)}>
                    Logout
                  </a>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-primary" href={loginURL}>
                  <FaSignInAlt style={loginButtonStyle} />
                  Login
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
